// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-cds-js": () => import("./../../../src/templates/cds.js" /* webpackChunkName: "component---src-templates-cds-js" */),
  "component---src-templates-fotoalbum-js": () => import("./../../../src/templates/fotoalbum.js" /* webpackChunkName: "component---src-templates-fotoalbum-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-repertoire-js": () => import("./../../../src/templates/repertoire.js" /* webpackChunkName: "component---src-templates-repertoire-js" */),
  "component---src-templates-standart-page-js": () => import("./../../../src/templates/standartPage.js" /* webpackChunkName: "component---src-templates-standart-page-js" */)
}

